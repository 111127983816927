/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import { BlogPostJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';

// eslint-disable-next-line
export const NewsLinkTemplate = ({ content, contentComponent, tags, title }) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">{title}</h1>
            <PostContent content={content} className="markdown-container" />
            {tags && tags.length ? (
              <div style={{ marginTop: '4rem' }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={`${tag}tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

NewsLinkTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

function NewsLink({ data }) {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <GatsbySeo
        title={post.frontmatter.title}
        titleTemplate="%s | Squaddie - The Caddie for your Golf Squad"
        description={post.frontmatter.description}
      />
      <BlogPostJsonLd
        title={post.frontmatter.title}
        headline={post.frontmatter.title}
        // need to add image
        // need to add url
        publisherName="Squaddie"
        authorName="Squaddie"
        authorType="organization"
        body={post.html}
        datePublished="2023-03-10T08:00:00+08:00"
        description={post.frontmatter.description}
        keywords={post.frontmatter.tags}
        images={[]}
        overrides={{
          '@id': post.frontmatter.title,
        }}
      />
      <NewsLinkTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
}

NewsLink.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default NewsLink;

export const pageQuery = graphql`
  query NewsLinkByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        source
        link
        tags
      }
    }
  }
`;
